import { createApp, defineAsyncComponent } from "vue";
import "./styles/main.scss";
import Popper from "vue3-popper";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { Vue3Mq } from "vue3-mq";

// defeat auto-scroll-restoration
if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

(() => {
  // A listener for Gravity Forms' AJAX-via-jQuery Event
  const onDOMContentLoaded = () => {
    if (!!window.jQuery) {
      window.jQuery(document).on(`gform_confirmation_loaded`, () => {
        window.dataLayer = window.dataLayer || [];
        if ( window.dataLayer.hasOwnProperty( "push" ) ) {
          window.dataLayer.push({
            "event": "Flu Outlook Form Submission"
          });
        }
      });
    }
  }
  document.addEventListener("DOMContentLoaded", onDOMContentLoaded );

  // Window state update
  const setQueryStringParameter = (name, value, append = false) => {
    const url = new URL(window.document.URL);

    if(append)
    {
      url.searchParams.append(name, value);
    }
    else
    {
      url.searchParams.set(name, value);
    }

    window.history.replaceState(null, '', url.toString());
  };

  // Window state clear
  const removeQueryStringParameter = (name) => {
    const url = new URL(window.document.URL);

    url.searchParams.delete(name);

    window.history.replaceState(null, '', url.toString());
  };

  // Blog filtering
  let blogFilters = document.querySelectorAll('input[name="blog-filter"]'),
      blogListing = document.querySelector('#blog-listing')
      ;

  if(blogFilters && blogListing)
  {
    const blogFilterChange = (event) => {
      if(event.target.value)
      {
        blogListing.className = 'blog-listing filtered';
        blogListing.classList.add(event.target.value);
        setQueryStringParameter('blog-filter', event.target.value);
      }
      else
      {
        blogListing.className = 'blog-listing';
        removeQueryStringParameter('blog-filter');
      }
    }
    blogFilters.forEach(radio => {
      radio.addEventListener('change', blogFilterChange);
    });
  }
})();

const Vue3MqOptions = {
  breakpoints: {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1024,
  },
  defaultBreakpoint: "sm",
};

const HeaderModel = {
    inject: ["mq"],
    data() {
      return {
        mobileMenuIsOpen: false,
      };
    },
    computed: {
      showMobileMenu() {
        return this.mobileMenuIsOpen && this.mq.mdMinus;
      },
    },
    methods: {
      onClickMobileMenuToggle(event) {
        if (!!this.mobileMenuIsOpen) {
          // menu was open
          // re-enable scroll
          enableBodyScroll(this.$refs.mobileMenu);
          this.mobileMenuIsOpen = false;
        } else {
          // menu was closed
          // disable scroll
          disableBodyScroll(this.$refs.mobileMenu);
          this.mobileMenuIsOpen = true;
        }
      },
    },
    mounted() {
      // use URL hash to deep link
      let target;
      if (!!window.location.hash) {
        // console.log( {'window.location.hash': window.location.hash.split( '?' )[ 0 ]} );
        target = document.querySelector(window.location.hash.split("?")[0]);
        // console.log( { target } );
        if (!!target) {
          target.scrollIntoView(); // 🤞
        }
      }
    },
    beforeUnmount() {
      // clear all body locks
      clearAllBodyScrollLocks();
    },
  },
  OmnibusModel = {
    inject: ["mq"],
    components: {
      Icons: defineAsyncComponent(() => import("./components/Icons.vue")),
      // "InfoTooltip": defineAsyncComponent(() => import("./components/InfoTooltip.vue")),
      Infotip: defineAsyncComponent(() =>
        import("./components/InfoTooltip.vue")
      ),
      Timeline: defineAsyncComponent(() =>
        import("./components/Timelines.vue")
      ),
      Popper: Popper,
    },
  };

const AppFactory = (viewModel) => {
  let appInstance = createApp({ ...viewModel });
  appInstance.use(Vue3Mq, Vue3MqOptions);
  return appInstance;
};

const ConditionalModelLoader = (selector, viewModel) => {
  let nodes = [...document.querySelectorAll(selector)];
  if (!!nodes.length) {
    nodes.forEach((node) => {
      AppFactory(viewModel).mount(node);
    });
  }
};

ConditionalModelLoader("#vue-header", HeaderModel);
ConditionalModelLoader("[ data-vue-bundle ]", OmnibusModel);
